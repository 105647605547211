/*
* @Author: Abhi Bhatt
* @Date:   2020-07-09 16:24:33
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-17 15:37:05
*/
import React from 'react';
//import {Link} from 'react-router-dom';
import StaticPagesService from './../../services/StaticPagesService/StaticPagesService';
import SearchBar from '../../components/SearchBar/SearchBar';


const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class ContactDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location_data:[]
		}
		this.staticPagesService = new StaticPagesService();
	}

	componentDidMount() {
		const permalink = this.props.match.params.route;
		this.staticPagesService.getStaticPage(permalink).then(request=>{
			if(request.status===200) {
				this.setState({location_data:request.data.result.static_page[0].fields})
			}
		});

	}

	render() {
		let location_data = this.state.location_data;
		return(
			<React.Fragment>
				<main role="main" class="middle-content-area">
					<div class="container-fluid">
						<SearchBar/>
						{renderHTML(location_data.content)}
					</div>	
				</main>
			</React.Fragment>
		)
	}
}

export default ContactDetails;