/*
* @Author: Abhi Bhatt
* @Date:   2020-07-09 15:11:39
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:53:04
*/
import React from 'react';
//import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
//import $ from 'jquery';

class CheckLogin extends React.Component{
	constructor(props) {
		super(props);
	}

	render() {
		let pathName = this.props.location.pathname;
		if(localStorage.getItem('techtop_user_id')!=='' && localStorage.getItem('techtop_user_id')!=null) {
			if(pathName==='/products/' || pathName==='/products') {
				return <Redirect to='/locations/' />
			}
		}
		else {
			if(pathName==='/products/' || pathName==='/products') {
				return <Redirect to='/user/login/' />
			}

		}
		return null;
	}
}

export default CheckLogin;