/*
* @Author: Abhi Bhatt
* @Date:   2020-06-23 15:38:25
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-06 12:39:44
*/
import React from 'react';
import {Link} from 'react-router-dom';
import ProductService from './../../services/ProductService/ProductService';
import SearchBar from '../../components/SearchBar/SearchBar';
import SideBar from '../../components/SideBar/SideBar';

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			product_detail:[],
			motor_data:[],
			product_id:'',
			user_id:'',
			is_login:0
		}
		this.productService = new ProductService();
		this.renderProductDetail = this.renderProductDetail.bind(this);
		this.renderMotors = this.renderMotors.bind(this);
	}

	componentDidMount() {
		const permalink = this.props.match.params.permalink;
		if(localStorage.getItem('techtop_user_id')!='' && localStorage.getItem('techtop_user_id')!=null) {
			this.setState({is_login:1});
			this.setState({user_id:localStorage.getItem('techtop_user_id')});
		}
		this.productService.getProductDetail(permalink).then(request=>{
			if(request.status===200) {
				//console.log(request.data.result.product_details[0]);
				this.setState({product_detail:request.data.result.product_details[0].product[0].fields});
				this.setState({product_id:request.data.result.product_details[0].product[0].pk});
				this.setState({motor_data:request.data.result.product_details[0].motor});
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const permalink = this.props.match.params.permalink;
    //if (prevState.categories_detail) { //Ensuring This is not the first call to the server
      if(permalink !== prevProps.match.params.permalink) {
      	this.productService.getProductDetail(permalink).then(request=>{
			if(request.status===200) {
				this.setState({product_detail:request.data.result.product_details[0].product[0].fields});
				this.setState({product_id:request.data.result.product_details[0].product[0].pk});
				this.setState({motor_data:request.data.result.product_details[0].motor});
			}
		});
      }
    //}

	}
	renderProductDetail() {
		let product_detail = this.state.product_detail;
		return(
			<React.Fragment>
				<div className="row mb-4">
					<div className="categoriesBox">
						<div className="col-md-12">
							<article className="product-details">
								<Link to="">
									<div className="row">
										<div className="col-md-12">
											<h3>{product_detail.name}</h3>
										</div>									
									</div>	
								</Link>
							</article>	
						</div>	
						<div className="col-md-12 mt-3">
							<article className="product">
								<div className="row">
									<div className="col-md-12">
										{renderHTML(product_detail.description)}
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

	renderMotors() {
		let motor_data = this.state.motor_data;
		let is_login = this.state.is_login;
		let product_detail = this.state.product_detail;
		if(motor_data && motor_data.length>0){
			return(
				<React.Fragment>
					<div className="row">
						<div className="col-md-12 mb-4">
							<article className="motors">
								{is_login===1?<p><Link to={"/products/"+product_detail.permalink+"/inventory"}>Show Inventory Table</Link></p>:null}
								<table className="table mt-5">
									<thead>
										<tr>
											<th>Part Number</th>
											<th>Motor Group</th>
											<th>Frame Size</th>
											<th>HP</th>
											<th>RPM</th>
											<th>Voltage</th>
											<th>List Price</th>
											<th>Multiplier Code</th>
											<th colSpan="2">Download</th>
											{is_login===1?<th>Inventory</th>:null}
										</tr>
									</thead>
									<tbody>
										{Object(motor_data).map(function(value,key){
											return(
												<React.Fragment key={key}>
													<tr>
														<td><Link to={'/motors/'+value.fields.part_number}>{value.fields.part_number}</Link></td>
														<td>{value.fields.group}</td>
														<td>{value.fields.frame_size}</td>
														<td>{value.fields.horsepower_60hz}</td>
														<td>{value.fields.rpm}</td>
														<td>{value.fields.voltage_60hz}</td>
														<td>{value.fields.price}</td>
														<td>{value.fields.multiplier_code}</td>
														<td colSpan="2"><Link to={'/motors/'+value.fields.part_number}>product details</Link></td>
														{is_login===1? <td><Link to={'/motors/'+value.fields.part_number+'/inventory'}>inventory</Link></td>: null }
													</tr>
												</React.Fragment>
											)
										})}
									</tbody>
								</table>
							</article>	
						</div>	
					</div>
				</React.Fragment>
			)
		}
	}

	render() {
		let is_login = this.state.is_login;
		let product_detail = this.state.product_detail;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
					<SearchBar/>
					<section className="product-area my-1">		
						<div className="row">
							<div className="col-md-12">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href={"/"}>Home</a><i className="fas fa-angle-right"></i></li>
									<li className="breadcrumb-item"><Link to={"/products/"}>Products</Link><i className="fas fa-angle-right"></i></li>
									<li className="breadcrumb-item"><Link to={"/categories/"+product_detail.category_permalink}>{product_detail.category_name}</Link><i className="fas fa-angle-right"></i></li>
									<li className="breadcrumb-item active">{product_detail.name}</li>
								</ol>
							</div>
						</div>		
						<div className="row">
							<div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
								<SideBar/>
							</div>
							<div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
								<div className="products">
									{this.renderProductDetail()}
									{this.renderMotors()}
								</div>
							</div>
						</div>
					</section>	
					</div>	
				</main>
			</React.Fragment>
		);
	}
}

export default Product;