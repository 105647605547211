/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 14:45:13
* @Last Modified by:   abhi
* @Last Modified time: 2021-07-24 16:18:48
*/
import React from 'react';
import {Link} from 'react-router-dom';
import NewsService from '../../services/NewsService/NewsService';
import SearchBar from '../../components/SearchBar/SearchBar';
import Moment from 'moment';

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class News extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			news_data:[]
		}
		this.newsService = new NewsService();
		this.renderNewsItems = this.renderNewsItems.bind(this);
	}

	componentDidMount() {
		this.newsService.getNews().then(response=>{
			if(response.status===200) {
				this.setState({news_data:response.data.result.news});

			}
		});

	}

	renderNewsItems() {
		let news_data = this.state.news_data;
		if(news_data && news_data.length>0) {
			return(
				Object(news_data).map(function(value,key){
					return(
						<React.Fragment key={key}>
							<div className="row py-3">
								<div className="col-md-12 pt-5 pb-3">
									<div className="newsBox">
										{renderHTML(value.fields.text)}
										{(value.fields.hide_date!==1)?
											<React.Fragment>
												<h5>{Moment(value.fields.created_at).format('MMMM DD, YYYY HH:mm')} </h5>
											</React.Fragment>
											:
											<React.Fragment>
											</React.Fragment>
										}
									</div>
								</div>
							</div>
							<hr/>
						</React.Fragment>
					)

				})
			)
		}
	}

	render() {
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
	<div className="container-fluid">
		<SearchBar/>
		<section className="news-area mt-2">		
			
			<div className="row">
				<div className="col-md-12 pt-3 pb-5">
					{this.renderNewsItems()}
				</div>
			</div>		
		</section>	
	</div>	
</main>
			</React.Fragment>
		)
	}
}

export default News;