/*
* @Author: Abhi Bhatt
* @Date:   2020-07-14 12:49:01
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:32
*/
import React from 'react';
import {Redirect} from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';

class Denegation extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
            errors: {},
            success_redirect:0,
            denegationToken:'',
            message:'',
            show_form:0,
            sendRequest:0
		}
		this.denegationForm = this.denegationForm.bind(this);
		this.successRedirect = this.successRedirect.bind(this);
		this.renderDenegationForm = this.renderDenegationForm.bind(this);
		this.renderDenegationEvent = this.renderDenegationEvent.bind(this);
		this.userService = new UserService();
	}

	componentDidMount() {
		this.setState({denegationToken:this.props.match.params.denegationToken})
		
	}

	denegationForm(e) {
		this.setState({message:''})
		e.preventDefault();
		if(this.handleValidation()) {
			this.userService.denegation(this.state.fields.email).then(response=>{
				if(response.status===200){
					if(response.data.response==='success'){
						$('#success_message').css('display','block');
            			$('#success_message').html(response.data.message);
						this.setState({success_redirect:1});
            			localStorage.setItem('show_message',1);
            			localStorage.setItem('success_message',response.data.message);
            			window.location.reload();
					}
				}
			});
		}

	}

	sendDenegation() {
		if(this.state.sendRequest===0){
			this.userService.sendDenegation(this.props.match.params.denegationToken).then(response=>{
				this.setState({sendRequest:1});
				console.log(response);
				if(response.status===200){
					if(response.data.response==='success'){
						$('#success_message').css('display','block');
	        			$('#success_message').html(response.data.message);
						this.setState({success_redirect:1});
	        			localStorage.setItem('show_message',1);
	        			localStorage.setItem('success_message',response.data.message);
	        			window.location.reload();
					} else if(response.data.response==='fail')  {
						console.log(response.data.message);
						this.setState({show_form:1,message:response.data.message})
					}
				}
			});	
		}
		
	}

	successRedirect() {
    	if(this.state.success_redirect===1) {
    		return <Redirect to="/" />
    	}
    }

	renderDenegationForm() {
		return(
			<React.Fragment>
				<form onSubmit={this.denegationForm.bind(this)}>
					<span style={{color:'red'}}>{this.state.message}</span>	
				</form>
			</React.Fragment>	
		)
	}

	renderDenegationEvent() {
		if(this.state.sendRequest===0){
			return(
				<React.Fragment>
					{this.sendDenegation()}
				</React.Fragment>
			)	
		}
		
	}

	render() {
		return(
			<React.Fragment>
			{this.successRedirect()}
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						<SearchBar/>
						<section className="new-area my-3">		
							<div className="row"> 
								<div className="col-md-12">
									<h5 className="pl-5" style={{fontSize:"1.2em"}}>The denegation instructions are being sent.</h5>
									<div className="row">
										<div className="col-md-6 offset-md-3">
											{(this.state.denegationToken!='' && typeof this.state.denegationToken!='undefined' && this.state.show_form===0) ? this.renderDenegationEvent() : this.renderDenegationForm() }
										</div>
									</div>	
								</div>
							</div>		
						</section>
					</div>	
				</main>
			</React.Fragment>
		);
	}
}

export default Denegation;