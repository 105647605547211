/*
* @Author: Abhi Bhatt
* @Date:   2020-07-18 16:27:49
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:26
*/

import React from 'react';
import {Link} from 'react-router-dom';
import SearchService from './../../services/SearchService/SearchService';
import SearchBar from '../../components/SearchBar/SearchBar';
import SideBar from '../../components/SideBar/SideBar';
import queryString from 'query-string';

class SearchMotor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search_data:{},
			is_login:0,
			searchString:''
		}
		this.searchService = new SearchService();
		this.renderTableData = this.renderTableData.bind(this);

	}

	componentDidMount() {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		//console.log(params);
		if(params!=='') {
			this.searchService.searchMotor(params).then(response=>{
				if(response.status===200){
					this.setState({search_data:response.data.result});
				}
			})
		}
		if(localStorage.getItem('techtop_user_id')!='' && localStorage.getItem('techtop_user_id')!=null) {
			this.setState({is_login:1});
		}

		let searchString = '';
		let url = this.props.location.search;
		let searchParams = queryString.parse(url);
		//if(searchParams.length>0){
			Object.keys(searchParams).map(function(value,key){
				if(value!==''){
					searchString+=value+'='+searchParams[value]+'&'
				}
			});
		//}
		//console.log(searchString);
		this.setState({searchString:searchString});
		//console.log(searchParams);
	}

	/*componentDidUpdate(prevProps, prevState){
		const search = window.location.search;
		const params = new URLSearchParams(search);
		if(params !== prevProps.params) {
			alert(prevProps.params+' -- '+params);
			this.searchService.searchMotor(params).then(response=>{
				if(response.status===200){
					this.setState({search_data:response.data.result});
				}
			})
		}
	}*/

	renderTableData(){
		let search_data = this.state.search_data;
		let is_login = this.state.is_login;	
		if(search_data && search_data.length>0){
			return Object(search_data).map(function(value,key){
				return (
				<React.Fragment key={key}>
					<tr>
						<td><Link to={'/motors/'+value.fields.part_number}>{value.fields.part_number}</Link></td>
						<td>{value.fields.group}</td>
						<td>{value.fields.frame_size}</td>
						<td>{value.fields.horsepower_60hz}</td>
						<td>{value.fields.rpm}</td>
						<td>{value.fields.voltage}</td>
						<td>{value.fields.price}</td>
						<td>{value.fields.multiplier_code}</td>
						<td colSpan="2"><Link to={'/motors/'+value.fields.part_number}>product details</Link></td>
						{is_login===1?<td><Link to={'/motors/'+value.fields.part_number+'/inventory'}>inventory</Link></td>:null}
					</tr>
				</React.Fragment>
				)
			});
		}
		else {
			return(
				<React.Fragment>
					<tr>
						<td colSpan="10"><h2>Result not found.</h2></td>
					</tr>
				</React.Fragment>
			)
		}
	}

	render() {
		let searchString = this.state.searchString;
		let is_login = this.state.is_login; 
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						<SearchBar/>
						<section className="product-area my-1">		
							<div className="row">
								<div className="col-md-12">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><a href="/">Home</a><i className="fas fa-angle-right"></i></li>
										<li className="breadcrumb-item"><a href="/products/">Products</a><i className="fas fa-angle-right"></i></li>
									</ol>
								</div>
							</div>		
							<div className="row">
								<div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
									<SideBar/>
								</div>
								<div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
									<div className="products">
										<div className="row">
											<div className="col-md-8">
												<h3><strong>Search Results</strong></h3>
											</div>
											<div className="col-md-4 text-right">
												{is_login===1?<p><Link to={'/search-motor-inventory/?'+searchString}>Show Inventory Table</Link></p>:null}
											</div>	
										</div>	
										<div className="row">
											<div className="col-md-12 mb-2">
												<article className="motors">
													<table className="table mt-5">
														<thead>
															<tr>
																<th>Part Number</th>
																<th>Motor Group</th>
																<th>Frame Size</th>
																<th>HP</th>
																<th>RPM</th>
																<th>Voltage</th>
																<th>List Price</th>
																<th>Multiplier Code</th>
																<th colSpan="2">Download</th>
																{is_login===1?<th>Inventory</th>:null}
															</tr>
														</thead>
														<tbody>
															{this.renderTableData()}
														</tbody>
													</table>
												</article>	
											</div>	
										</div>	
								
									</div>
								</div>
							</div>
						</section>
					</div>	
				</main>
			</React.Fragment>
		)
	}
}

export default SearchMotor;