/*
* @Author: Abhi Bhatt
* @Date:   2020-07-18 16:12:27
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-21 13:54:59
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';
import $ from 'jquery';

class SearchService {
	async searchMotor(formFields) {
		const url = `${API_URL}/search-motor/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url+'?'+formFields,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});

	}

	async searchMotorInventory(formFields) {
		const url = `${API_URL}/search-motor-inventory/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url+'?'+formFields,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async searchBarValues() {
		const url = `${API_URL}/searchbar-values/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

}

export default SearchService