/*
* @Author: Abhi Bhatt
* @Date:   2020-07-14 10:56:04
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:58
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';
import $ from 'jquery';

class UserService {
	async login(formFields) {
		const url = `${API_URL}/login/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url+''+formFields.email+'/'+formFields.password+'/',
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async signUp(formFields) {
		const url = `${API_URL}/signup/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		var formValue;
		$.each(formFields,function(key,value){
			if(key!=='conf_password') {
				if(key==='user_email') {
					key = 'email';	
				}
				formValue+= key+'='+value+'&';
			}
			
		});
		formValue = formValue.replace("undefined","");
		return axios({
			url:url+'?'+formValue,
			method: 'get',
			crossDomain:true,
			data: formValue,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});

	}

	async logout() {
		localStorage.clear();
		let response = {'status':200,'response':'success','message':'logout successfully.'};
		return response;
	}

	async forgotPassword(formFields) {
		console.log(formFields.email);
		const url = `${API_URL}/forget-password/${formFields.email}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async resetPassword(resetPasswordToken,formFields) {
		const url = `${API_URL}/reset-password/${resetPasswordToken}/${formFields.encrypted_password}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async confirmation(email) {
		const url = `${API_URL}/send-confirmation/${email}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async sendConfirmation(confirmationToken) {
		console.log(confirmationToken);
		const url = `${API_URL}/active-user/${confirmationToken}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async sendDenegation(denegationToken) {
		const url = `${API_URL}/deny-user/${denegationToken}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}
export default UserService;