import React from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import UtilService from '../../services/UtilService/UtilService';
import './EASA.css'; // Asegúrate de importar el archivo CSS

class EASA extends React.Component {
    constructor(props) {
        super(props);
        this.utilService = new UtilService();
        this.state = {
            formData: {
                firstName: '',
                lastName: '',
                companyName: '',
                billingAddress: '',
                comments: ''
            }
        };
    }

    componentDidMount() {
        console.log("Component EASA mounted.");
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
            }
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await this.utilService.sendFormData(this.state.formData);
            alert('Email sent successfully');
            window.location.href = 'http://www.techtopind.com'; // Redirigir a la página principal
        } catch (error) {
            alert('Failed to send email');
        }
    };

    render() {
        return (
            <React.Fragment>
                <main role="main" className="middle-content-area">
                    <div className="container-fluid">
                        <SearchBar />
                        <div className="form-container">
                            <form onSubmit={this.handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>
                                    First Name
                                    <input type="text" name="firstName" value={this.state.formData.firstName} onChange={this.handleChange} required />
                                </label>
                                <label>
                                    Last Name
                                    <input type="text" name="lastName" value={this.state.formData.lastName} onChange={this.handleChange} required />
                                </label>
                                <label>
                                    Company Name
                                    <input type="text" name="companyName" value={this.state.formData.companyName} onChange={this.handleChange} required />
                                </label>
                                <label>
                                    Billing Address
                                    <textarea name="billingAddress" value={this.state.formData.billingAddress} onChange={this.handleChange} required rows="4" />
                                </label>
                                <label>
                                    Any comments?
                                    <textarea name="comments" value={this.state.formData.comments} onChange={this.handleChange} rows="4" />
                                </label>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default EASA;

