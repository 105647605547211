/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 11:33:34
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-10 20:02:53
*/
import React from 'react';
import {Link} from 'react-router-dom';
import DownloadService from '../../services/DownloadService/DownloadService';
import SearchBar from '../../components/SearchBar/SearchBar';

class Downloads extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			download_data:[]
		};
		this.downloadService = new DownloadService();
		this.renderDownloadItems = this.renderDownloadItems.bind(this);
	}

	componentDidMount() {
		this.downloadService.getDownload().then(response=>{
			if(response.status===200) {
				let download_categories = response.data.result.download_categories;
				this.setState({download_data:download_categories})
			}
		});
	}

	renderDownloadItems() {
		let download_data = this.state.download_data;
		if(download_data && download_data.length>0){
			return Object(download_data).map(function(value,key){
				return(
					<React.Fragment key={key}>

						<div className="col-md-12">
							<h4>{value.downloads_category[0].fields.name}</h4>
								<div className="row">
									<div className="col-md-10 offset-md-1">
										<div className="download_items">
											<div className="row">
												
											{Object(value.download_items).map(function(value1,key1){
												return(
													<React.Fragment key={key+'-'+key1}>
														<div className="col-md-3 col-sm-3 col-lg-2 col-xl-2 col-6">
															<a href={value1.fields.pdf_document_file_name}><img src={value1.fields.image_file_name} alt="" className="d-block img-fluid mx-auto w-100"/></a>
														</div>
													</React.Fragment>
												)
											})}
											</div>
										</div>
									</div>	
								</div>
								<hr/>
						</div>
					</React.Fragment>
				)
			})
		}
	}

	render(){
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
	<div className="container-fluid">
		<SearchBar/>

		<section className="downloads-area mt-2">		
			<div className="row mb-4">
				{this.renderDownloadItems()}
			</div>
		</section>	
	</div>	
</main>
			</React.Fragment>
		)
	}

}

export default Downloads