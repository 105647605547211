/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 11:33:34
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-10 20:02:53
*/
import React from 'react';
import {Link} from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';

class Catalog extends React.Component{
	constructor(props) {
		super(props);
		
	}

	componentDidMount() {
		
	}

	
	render(){
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
	<div className="container-fluid">
		<SearchBar/>

		<div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '640px', // Establecer la altura deseada
              }}
            >
              <iframe
                src="https://online.flippingbook.com/view/18154505/"
                className="fbo-embed"
                data-fbo-id="5d3ba236c1"
                data-fbo-ratio="3:2"
                data-fbo-lightbox="yes"
				class="fbo-embed" 
				data-fbo-id="5d3ba236c1" 
				data-fbo-width="100%" 
				data-fbo-height="auto" 
				data-fbo-version="1" 
				
                style={{
                  width: '100%', // Establecer el ancho al 50%
                  minHeight: '640px', // Establecer la altura mínima a 1024px
                }}
              >
                Techtop Bookshelf
              </iframe>
            </div>

	
	</div>	
</main>
			</React.Fragment>
		)
	}

}

export default Catalog