/*
* @Author: Abhishek Bhatt
* @Date:   2021-07-24 14:39:47
* @Last Modified by:   abhi
* @Last Modified time: 2021-07-24 15:52:02
*/
import React from 'react';
//import {Link} from 'react-router-dom';
import StaticPagesService from './../../services/StaticPagesService/StaticPagesService';
import SearchBar from '../../components/SearchBar/SearchBar';

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class StaticPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[]
		}
		this.staticPagesService = new StaticPagesService();
	}

	componentDidMount() {
		const permalink = this.props.match.params.route;
		this.staticPagesService.getStaticPage(permalink).then(response=>{
			if(response.status===200) {
				this.setState({data:response.data.result.static_page[0].fields})
			}
		});
	}

	render() {
		let { data } = this.state;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
				<div className="container-fluid">
					<SearchBar/>
					<section className="contact-area mt-4">		
						<div className="row">
							<div className="col-md-10 col-sm-10 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 col-12 py-3">
								{(typeof data.content!=='undefined' && data.content!=='')?
									renderHTML(data.content)
								:
									''
								}
							</div>
						</div>		
					</section>	
				</div>	
			</main>
			</React.Fragment>
		)
	}

}

export default StaticPage;