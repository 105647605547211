import axios from 'axios';
import {API_URL} from '../../constants.js';

class UtilService {

  async getDoeCount() {
    const url = `//admin.techtopind.net/statistics/get_doevideo`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async incrementDoeCount() {
    const url = `//admin.techtopind.net/statistics/increment_and_get_doevideo`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async sendFormData(formData) {
    const url = `http://127.0.0.1:8000/statistics/send-email`;

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

}

export default UtilService;
