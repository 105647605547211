/*
* @Author: Abhi Bhatt
* @Date:   2020-07-09 16:50:01
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-17 15:32:57
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';
//import $ from 'jquery';

class LocationService {
	async getLocations() {
		const url = `${API_URL}/get-locations/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		//let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			//data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getLocationDetail(permalink) {
		const url = `${API_URL}/get-location-details/${permalink}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		//let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			//data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}

export default LocationService; 