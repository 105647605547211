/*
* @Author: Abhi Bhatt
* @Date:   2020-06-24 12:35:52
* @Last Modified by:   abhi
* @Last Modified time: 2021-07-24 15:38:09
*/
import React from 'react';
//import {Link} from 'react-router-dom';
import StaticPagesService from './../../services/StaticPagesService/StaticPagesService';
import SearchBar from '../../components/SearchBar/SearchBar';


const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contact_data:[]
		}
		this.staticPagesService = new StaticPagesService();
	}

	componentDidMount() {
		const permalink = 'contact';
		this.staticPagesService.getStaticPage(permalink).then(request=>{
			if(request.status===200) {
				this.setState({contact_data:request.data.result.static_page[0].fields})
				//console.log(request.data.result.static_page[0].fields);
			}
		});

	}

	render() {
		let contact_data = this.state.contact_data;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						<SearchBar/>
						<section className="contact-area mt-4">		
							<div className="row">
								<div className="col-md-10 col-sm-10 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 col-12 py-3">
									{renderHTML(contact_data.content)}
									
								</div>
							</div>		
						</section>	
					</div>	
				</main>
			</React.Fragment>
		)
	}
}

export default Contact;