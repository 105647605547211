import $ from 'jquery';
import jQuery from 'jquery'

window.jQuery = jQuery

// dynamically require owl
//require('owl.carousel')
 $(document).ready(function () {
    setTimeout(function(){
	var owl = $('#owl-carousel1');
    owl.owlCarousel({
        loop: true,
        margin:20,
        nav: true,
        dots: true,
        items: 1,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 1,
                nav: false
            },
            1000: {
                items: 1,
                nav: false,
                loop: false
            }
        }
    });
},800);
 setTimeout(function(){   
	var owl = $('#owl-carousel2');
    owl.owlCarousel({
        loop: true,
        margin:10,
        nav: true,
        dots: true,
        items: 5,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 5,
                nav: false,
                loop: false
            }
        }
    });
    var owl = $('#owl-carousel3');
    owl.owlCarousel({
        loop: true,
        margin:10,
        nav: true,
        dots: false,
        items: 5,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 5,
                nav: false,
                loop: false
            }
        }
    });
},4000);
	
	//$.responsiveTables();	
});