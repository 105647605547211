import React from 'react';
import { Link } from 'react-router-dom';
import MotorService from './../../services/MotorService/MotorService';
import SearchBar from '../../components/SearchBar/SearchBar';
import SideBar from '../../components/SideBar/SideBar';

class Motor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      motor_detail: [],
      user_id: '',
      is_login: 0,
      media_url: '',
      isLoading: true // Estado para controlar la carga de datos
    };
    this.motorService = new MotorService();
  }

  componentDidMount() {
    const part_number = this.props.match.params.part_number;
    if (localStorage.getItem('techtop_user_id') !== '' && localStorage.getItem('techtop_user_id') != null) {
      this.setState({ is_login: 1 });
      this.setState({ user_id: localStorage.getItem('techtop_user_id') });
    }
    this.setState({ isLoading: true }); // Establecer isLoading en true antes de la petición
    this.motorService.getMotorDetail(part_number).then(response => {
      if (response.status === 200) {
        if (typeof response.data.result.motor_detail[0] !== 'undefined') {
          this.setState({
            media_url: response.data.media_url,
            motor_detail: response.data.result.motor_detail[0].fields,
            isLoading: false // Establecer isLoading en false después de obtener los datos
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const part_number = this.props.match.params.part_number;
    if (part_number !== prevProps.match.params.part_number) {
      this.motorService.getMotorDetail(part_number).then(response => {
        if (typeof response.data.result.motor_detail[0] !== 'undefined') {
          this.setState({ media_url: response.data.media_url, motor_detail: response.data.result.motor_detail[0].fields });
        }
      });
    }
  }

  renderMotorDetail() {
	let motor_detail = this.state.motor_detail;
	let is_login = this.state.is_login;
  
	return (
	  <React.Fragment>
		<div className="products">
		  <div className="row">
			<div className="col-md-8">
			  <h3><strong>{motor_detail.part_number}</strong></h3>
			</div>
			<div className="col-md-4 text-right">
			  {is_login === 1 ? <p><Link to={'/motors/' + motor_detail.part_number + '/inventory'}>Show Inventory</Link></p> : null}
			</div>
		  </div>
		  <div className="row">
			<div className="col-md-12 mb-2">
			  <article className="motors">
				<table className="table" style={{ borderTop: '10px solid rgb(187, 93, 64)', borderBottom: '10px solid rgb(187, 93, 64)' }}>
				  <tbody>
					<tr>
					  <td>Motor Group</td>
					  <td>{motor_detail.group}</td>
					</tr>
					<tr>
					  <td>Frame Size</td>
					  <td>{motor_detail.frame_size}</td>
					</tr>
					<tr>
					  <td>HP</td>
					  <td>{motor_detail.horsepower_60hz}</td>
					</tr>
					<tr>
					  <td>RPM</td>
					  <td>{motor_detail.rpm}</td>
					</tr>
					<tr>
					  <td>Voltage</td>
					  <td>{motor_detail.voltage_60hz}</td>
					</tr>
					<tr>
					  <td>List Price</td>
					  <td>{motor_detail.price}</td>
					</tr>
					<tr>
					  <td>Multiplier Code</td>
					  <td>{motor_detail.multipler_code}</td>
					</tr>
					<tr>
					  <td>Mounting</td>
					  <td>Rigid</td>
					</tr>
				  </tbody>
				</table>
				<ul>
				  {(motor_detail.drawing_pdf_id !== '' && motor_detail.drawing_pdf_id !== null) ? (
					<li>
					  <a href={this.state.media_url + 'drawing_pdf/' + motor_detail.drawing_pdf_id}>2D drawing</a>
					</li>
				  ) : null}
				  {(motor_detail.url_3d !== '' && motor_detail.url_3d !== null) ? (
					<li><a href={motor_detail.url_3d}>3D drawing</a></li>
				  ) : null}
				  <li><a href={this.state.media_url + 'datasheets/' + motor_detail.part_number + '.pdf'}>datasheet</a></li>
				</ul>
			  </article>
			</div>
		  </div>
		</div>
	  </React.Fragment>
	);
  }
  

  render() {
    const { isLoading } = this.state;

	if (isLoading) {
		return (
		  <div className="loading-indicator" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontSize: '24px' }}>
			<i className="fas fa-hourglass" style={{ marginRight: '10px' }}></i>
			<h2>Loading...</h2>
		  </div>
		);
	  }

    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <SearchBar />
            <section className="product-area my-1">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb">
                    {/* Renderizado de elementos de breadcrumb */}
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
                  <SideBar />
                </div>
                <div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
                  {this.renderMotorDetail()}
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Motor;
