import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import UtilService from '../../services/UtilService/UtilService'; 


class DOE extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            showPlayButton: true,
            doeCount: 0
        };
    }

    handleAppointmentClick = () => {
        const checkScript = () => {
            if (window.daySchedule && typeof window.daySchedule.initPopupWidget === 'function') {
                window.daySchedule.initPopupWidget({
                    "url": "https://techtopind.dayschedule.com",
                    "type": "popup",
                    "color": {
                        "primary": "#fa4616",
                        "secondary": "#ffffff"
                    }
                });
            } else {
                console.error('daySchedule script is not loaded or not properly initialized.');
            }
        };
    
        if (window.daySchedule && typeof window.daySchedule.initPopupWidget === 'function') {
            checkScript();
        } else {
            setTimeout(checkScript, 1000);  // Check again after 1 second
        }
    }
    
    
    
    
    playVideo = () => {
        if (this.videoRef.current) {
            this.videoRef.current.play();
            
            // incremetnts when "Play Video"
            const utilService = new UtilService();
            utilService.incrementDoeCount()
              .then((newCount) => {
                this.setState({ doeCount: newCount });
              })
              .catch((error) => {
                console.error('Error:', error);
              });
        }
    }


    hidePlayButton = () => {
        this.setState({ showPlayButton: false });
    }

    componentDidMount() {
        if (this.videoRef.current) {
            this.videoRef.current.addEventListener('play', this.hidePlayButton);
        }

        const utilService = new UtilService();
        utilService.getDoeCount()
          .then((count) => {
            console.log('Doe Count:', count);
            this.setState({ doeCount: count });
          })
          .catch((error) => {
            console.error('Error Doe:', error);
          });

    }

    
    componentWillUnmount() {
        if (this.videoRef.current) {
            this.videoRef.current.removeEventListener('play', this.hidePlayButton);
        }
    }

    render() {
        return (
            <React.Fragment>
                <main role="main" className="middle-content-area">
                    <div className="container-fluid">
                        <SearchBar />
    
    
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
    
                          {/* Added this flex container to hold both the Appointment Button and DOE Banner side-by-side */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '20px' 
                        }}>
                            <img
                                src="https://www.techtopind.com/static/media/Appointment Button.png"
                                alt="Appointment Button"
                                style={{ width: '10%', marginRight: '20px', cursor: 'pointer' }}  // Added cursor: 'pointer'
                                onClick={this.handleAppointmentClick}
                            />

                            <img
                                src="https://www.techtopind.com/static/media/DOE%20Banner.svg"
                                alt="DOE Banner"
                                style={{ width: '50%' }}
                            />
                        </div>
    
                            <div style={{
                                width: '75%',
                                height: '480px',
                                position: 'relative',
                                display: 'inline-block'
                            }}>
                                <video
                                    ref={this.videoRef}
                                    width="100%"
                                    height="100%"
                                    controls
                                    src="https://www.techtopind.com/static/media/Techtop_animation_FINAL.mp4"
                                    title="Video de Techtop"
                                >
                                    Your browser doesn't support video player.
                                </video>
    
                                {this.state.showPlayButton && (
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        zIndex: 5
                                    }}></div>
                                )}
    
                                {this.state.showPlayButton && (
                                    <button
                                        onClick={this.playVideo}
                                        style={{
                                            position: 'absolute',
                                            zIndex: 10,
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            background: '#000',
                                            color: '#fff',
                                            padding: '15px 30px',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            border: '3px solid #fff',
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                            letterSpacing: '1px',
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        Play Video
                                    </button>
                                )}
                            </div>
    
                            <div style={{
                                fontSize: '12px',
                                fontWeight: 'light'
                            }}>
                                View Count: {this.state.doeCount.DOEvideo !== null ? this.state.doeCount.DOEvideo : 0}
                            </div>
    
                                {/* Buttons */}
                                <div style={{ 
                                marginTop: '20px', 
                                display: 'flex', 
                                justifyContent: 'space-around', // Adjusts the space between each element
                                alignItems: 'center', // Vertically aligns elements
                                width: '80%', // Controls the total width of the buttons’ container
                                marginLeft: 'auto', // Horizontally centers the container when used with marginRight
                                marginRight: 'auto'
                            }}>
                                <a href="https://www.techtopind.com/static/media/Distribution versus Utilization voltage.pdf" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="https://www.techtopind.com/static/media/Distribution versus Utilization voltage.svg"
                                        alt="Button 1"
                                        style={{ cursor: 'pointer', width: '300px', height: '300px' }} // Adjust width and height as per your need
                                    />
                                </a>
    
                                <a href="https://www.techtopind.com/static/media/Motor Voltage Change (1).pdf" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="https://www.techtopind.com/static/media/Motor Voltage Change Button.svg"
                                        alt="Button 2"
                                        style={{ cursor: 'pointer', width: '300px', height: '300px' }}
                                    />
                                </a>
    
                                <a href="https://www.techtopind.com/static/media/Namplate Data Change Flyer.pdf" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="https://www.techtopind.com/static/media/New DOE Law.png"
                                        alt="Button 3"
                                        style={{ cursor: 'pointer', width: '300px', height: '300px' }}
                                    />
                                </a>
                            </div>

                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default DOE;
