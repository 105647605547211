/*
* @Author: Abhi Bhatt
* @Date:   2020-06-17 16:06:03
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-06-17 17:17:18
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';

class SliderSrvice {
	async getBannerImages() {
		const url = `${API_URL}/get-banner-images/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}

export default SliderSrvice;
