/*
* @Author: Abhi Bhatt
* @Date:   2020-06-24 11:26:05
* @Last Modified by:   abhi
* @Last Modified time: 2021-07-24 18:52:24
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';

class MotorService {
	async getMotorDetail(part_number) {
		const url = `${API_URL}/get-motor-details/${part_number}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getInventory(part_number) {
		const url = `${API_URL}/get-inventory/${part_number}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getInventoryOld(part_number) {
		const url = `${API_URL}/get-inventory-old/${part_number}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async searchMotorList(part_number) {
		part_number = part_number.toUpperCase();
		const url = `${API_URL}/search-motor-list/${part_number}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}

export default MotorService;