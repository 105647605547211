/*
* @Author: Abhi Bhatt
* @Date:   2020-06-25 11:16:48
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-06 12:40:00
*/
import React from 'react';
import {Link} from 'react-router-dom';
import MotorService from './../../services/MotorService/MotorService';
import SearchBar from '../../components/SearchBar/SearchBar';
import SideBar from '../../components/SideBar/SideBar';

class MotorInventory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inventory_data:[],
			motor_details:[],
			part_number:''

		}
		this.motorService = new MotorService();
		this.renderInventory = this.renderInventory.bind(this);
	}

	componentDidMount() {
		const part_number = this.props.match.params.part_number;
		this.setState({part_number:this.props.match.params.part_number});
		this.motorService.getInventory(part_number).then(response=>{
			if(response.status===200) {
				this.setState({inventory_data:response.data.result.inventory_places});
				this.setState({motor_details:response.data.result.motor_detail[0]});
			}
		});

	}

	renderInventory() {
		let inventory_data = this.state.inventory_data;
		return Object(inventory_data).map(function(value,key){
			return(
				<React.Fragment key={key}>
					<tr>
						<td>{value.fields.inventory_place}</td>
						<td>{value.fields.count}</td>
					</tr>
				</React.Fragment>
			)
		});
	}

	render() {
		let part_number = this.state.part_number;
		const { motor_details } = this.state;
		if(typeof motor_details.fields !=='undefined'){
			console.log(motor_details.fields.category_name);
		}
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						<SearchBar/>
						<section className="product-area my-1">		
							<div className="row">
								<div className="col-md-12">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><a href={"/"}>Home</a><i className="fas fa-angle-right"></i></li>
										<li className="breadcrumb-item"><Link to={"/products/"}>Products</Link><i className="fas fa-angle-right"></i></li>
										{(typeof motor_details.fields !=='undefined')?
											<React.Fragment>
												<li className="breadcrumb-item"><Link to={"/categories/"+motor_details.fields.category_permalink}>{motor_details.fields.category_name}</Link><i className="fas fa-angle-right"></i></li>
												<li className="breadcrumb-item active">{motor_details.fields.product_name}</li>
											</React.Fragment>
											:
											<React.Fragment>
											</React.Fragment>
										}
									</ol>
								</div>
							</div>		
							<div className="row">
								<div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
									<SideBar/>
								</div>
								<div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
									<div className="products">
										<div className="row">
											<div className="col-md-8">
												<h3><strong>{part_number}</strong></h3>
											</div>
											<div className="col-md-4 text-right">
												<p><Link to={'/motors/'+part_number}>Show Info</Link></p>
											</div>	
										</div>	
										<div className="row">
											<div className="col-md-12 mb-2">
												<article className="motors">
													<table className="table" style={{borderTop: '10px solid rgb(187, 93, 64)', borderBottom: '10px solid rgb(187, 93, 64)'}}>
														<thead>
															<tr>
																<th>Location</th>
																<th>Quantity</th>
															</tr>
														</thead>
														<tbody>
															{this.renderInventory()}
														</tbody>
													</table>
												</article>	
											</div>	
										</div>	
								
									</div>
								</div>
							</div>
						</section>
					</div>	
				</main>	
			</React.Fragment>
		)

	}
}

export default MotorInventory;